/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "gatsby-plugin-react-i18next"

const getSchemaOrgJSONLD = ({ url, title, blogPost }) => {
  const schemaOrgJSONLD = [
    {
      "@context": "http://schema.org",
      "@type": "WebSite",
      url,
      name: title,
      alternateName: "InshAllah",
    },
  ]

  return blogPost
    ? [
        ...schemaOrgJSONLD,
        {
          "@context": url,
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              item: {
                name: blogPost.category.name,
                item:
                  "https://www.inshallah.com/blog/" +
                  blogPost.category.seo.slug,
              },
            },
            {
              "@type": "ListItem",
              position: 2,
              item: {
                // "@id": url,
                name: blogPost.title,
                image: blogPost.coverPicture
                  ? blogPost.coverPicture.file.url
                  : null,
                item: `https://www.inshallah.com/blog/${blogPost.category.seo.slug}/${blogPost.seo.slug}`,
              },
            },
          ],
        },
        {
          "@context": url,
          "@type": "BlogPosting",
          url: `https://www.inshallah.com/blog/${blogPost.category.seo.slug}/${blogPost.seo.slug}`,
          name: blogPost.title,
          headline: blogPost.title,
          image: {
            "@type": "ImageObject",
            url: blogPost.coverPicture ? blogPost.coverPicture.file.url : null,
          },
          description: blogPost.seo.description,
          author: {
            "@type": "Person",
            name: blogPost.author.name,
          },
          publisher: {
            "@type": "Organization",
            url,
            logo: "https://www.inshallah.com/icons/icon-512x512.png",
            name: blogPost.author.name,
          },
          mainEntityOfPage: {
            "@type": "WebSite",
            "@id": url,
          },
          datePublished: blogPost.createdAt,
        },
      ]
    : schemaOrgJSONLD
}
function SEO({ description, lang, meta, title, blogPost }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  )

  const metaDescription =
    description && description.length
      ? description
      : site.siteMetadata.description
  const metaTitle = title && title.length ? title : site.siteMetadata.title

  const schemaOrgJSONLD = getSchemaOrgJSONLD({
    url: site.siteMetadata.siteUrl,
    title: metaTitle,
    blogPost,
  })

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      // titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: site.siteMetadata.title,
        },
        {
          property: `og:description`,
          content: site.siteMetadata.description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: blogPost ? blogPost.author.name : site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
